import { Link } from 'gatsby';
import tw, { styled } from 'twin.macro';

export const LogoLink = styled(Link)`
  ${tw`block`}
  ${tw`mr-2`}
`;

export const NavigationWrapper = styled.nav`
  ${tw`sticky h-[100vh] top-12`}
  ${tw`overflow-x-hidden overscroll-y-contain`}
  ${tw`flex flex-col`}
  ${tw`px-14 pr-0 pb-16`}
  overflow-y: overlay;
`;

export const NavigationHeader = tw.header`
  relative
  flex flex-row items-center
  mt-12 mb-6
`;

export const Separator = tw.div`
  w-[2px]
  h-[30px]
  bg-borderColor
  mx-2
`;

export const NavigationContent = styled.div`
  ${tw`pr-14`}
`;
