import { ProductItem } from '@hooks/useProducts';
import { StyledImage } from './Logo.styles';
export interface LogoProps extends ProductItem {
  isListItem?: boolean;
}

const Logo = ({ logo, logoTitle, name: productName, isListItem = false }: LogoProps) => {
  return (
    <StyledImage
      alt={logo.value.description || productName}
      loading="eager"
      objectFit="contain"
      objectPosition="left"
      image={{
        height: 45,
        image_id: logo.value.url,
        url: logo.value.url,
        width: 188,
      }}
      title={logoTitle?.value || productName}
    />
  );
};

export default Logo;
